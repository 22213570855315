import { useState, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { Authme } from '../services/auth';
import MainLayout from 'layout/MainLayout';
import Loader from 'ui-component/Loader';
import { useDispatch } from 'react-redux';
function Protected() {
    const [isAuth, setIsAuth] = useState(null);
    const dispatch = useDispatch();
    const redirect = useNavigate();
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await Authme();
                // check the user role if they are super admin
                if (res.status === 200) {
                    if (res.data.tenants[0].roles[0] === 'superAdmin') {
                        setIsAuth(true);
                        localStorage.setItem('tenant', res.data.tenants[0].tenant.id);
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        dispatch({ type: 'SET_USER', payload: res.data });
                    } else {
                        setIsAuth(false);
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        redirect('/auth/login');
                    }
                }
            } catch (error) {
                setIsAuth(false);
            }
        };
        fetchUser();
    }, []);
    if (isAuth === null) {
        return <Loader />;
    }

    return isAuth ? (
        <MainLayout>
            <Outlet />
        </MainLayout>
    ) : (
        <Navigate to="/auth/login" />
    );
}

export default Protected;
