export const initialState = {
  activeVerticalStep: 0,
  activeHorizontalStep: 0,
  activeTab: '1',
  activeShop: '',
  shopData: [],
  sectionsData: [],
  tableData: [],
  productData: [],
  variantData: [],
  attributeGroupsData: [],
  attributeData: [],
  categoryData: [],
  selectedCompany: {},
  companyFormMessage: null,
  shopFormMessage: null,
  sectionsFormMessage: null,
  tablesFormMessage: null,
  companyForm: {
    companyName: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    password: ''
  },
  shopForm: {
    shopName: '',
    shopAccountType: '',
    email: '',
    telephone: '',
    active: false
  },
  sectionsForm: {
    sectionName: '',
    description: '',
    surcharge: '',
    usePercentage: '',
    active: false
  },
  tablesForm: {
    tableNumber: '',
    description: '',
    reserved: '',
    fromTime: null,
    toTime: null,
    reserverName: ''
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_VERTICAL_STEP':
      return {
        ...state,
        activeVerticalStep: action.payload
      };
    case 'SET_HORIZONTAL_STEP':
      return {
        ...state,
        activeHorizontalStep: action.payload
      };
    case 'SET_TAB':
      return {
        ...state,
        activeTab: action.payload
      };
    case 'SET_ACTIVE_SHOP':
      return {
        ...state,
        activeShop: action.payload
      };
    case 'SET_SHOP_DATA':
      return {
        ...state,
        shopData: action.payload
      };
    case 'SET_SECTIONS_DATA':
      return {
        ...state,
        sectionsData: action.payload
      };
    case 'SET_TABLE_DATA':
      return {
        ...state,
        tableData: action.payload
      };
    case 'SET_PRODUCT_DATA':
      return {
        ...state,
        productData: action.payload
      };
    case 'SET_VARIANT_DATA':
      return {
        ...state,
        variantData: action.payload
      };
    case 'SET_ATTRIBUTE_GROUPS_DATA':
      return {
        ...state,
        attributeGroupsData: action.payload
      };
    case 'SET_ATTRIBUTE_DATA':
      return {
        ...state,
        attributeData: action.payload
      };
    case 'SET_CATEGORY_DATA':
      return {
        ...state,
        categoryData: action.payload
      };
    case 'SET_SELECTED_COMPANY':
      return {
        ...state,
        selectedCompany: action.payload
      };
    case 'SET_COMPANY_FORM_MESSAGE':
      return {
        ...state,
        companyFormMessage: action.payload
      };
    case 'SET_SECTIONS_FORM_MESSAGE':
      return {
        ...state,
        sectionsFormMessage: action.payload
      };
    case 'SET_TABLES_FORM_MESSAGE':
      return {
        ...state,
        tablesFormMessage: action.payload
      };
    case 'SET_SHOP_FORM_MESSAGE':
      return {
        ...state,
        shopFormMessage: action.payload
      };
    case 'SET_COMPANY':
      return {
        ...state,
        companyForm: action.payload
      };
    case 'SET_SHOP':
      return {
        ...state,
        shopForm: action.payload
      };
    case 'RESET_SHOP':
      return {
        ...state,
        shopForm: {
          shopName: '',
          shopAccountType: '',
          email: '',
          telephone: '',
          active: false
        }
      };
    case 'RESET_TABLE':
      return {
        ...state,
        tablesForm: {
          tableNumber: '',
          description: '',
          reserved: '',
          fromTime: null,
          toTime: null,
          reserverName: ''
        }
      };
    case 'RESET_SECTION':
      return {
        ...state,
        sectionsForm: {
          sectionName: '',
          description: '',
          surcharge: '',
          usePercentage: '',
          active: false
        }
      };
    case 'DELETE_SHOPS':
      return {
        ...state,
        shopData: [...state.shopData?.filter((shop) => !action.payload.includes(shop.id))]
      };

    case 'DELETE_SECTIONS':
      return {
        ...state,
        sectionsData: [...state.sectionsData?.filter((section) => !action.payload.includes(section.id))]
      };
    case 'DELETE_TABLES':
      return {
        ...state,
        tableData: [...state.tableData?.filter((table) => !action.payload.includes(table.id))]
      };
    case 'DELETE_PRODUCTS':
      return {
        ...state,
        productData: [...state.productData?.filter((product) => !action.payload.includes(product.id))]
      };
    case 'DELETE_VARIANTS':
      return {
        ...state,
        variantData: [...state.variantData?.filter((variant) => !action.payload.includes(variant.id))]
      };
    case 'DELETE_ATTRIBUTE_GROUPS':
      return {
        ...state,
        attributeGroupsData: [
          ...state.attributeGroupsData?.filter(
            (attributeGroup) => !action.payload.includes(attributeGroup.id)
          )
        ]
      };
    case 'DELETE_ATTRIBUTES':
      return {
        ...state,
        attributeData: [...state.attributeData?.filter((attribute) => !action.payload.includes(attribute.id))]
      };
    case 'DELETE_CATEGORIES':
      return {
        ...state,
        categoryData: [...state.categoryData?.filter((category) => !action.payload.includes(category.id))]
      };
    case 'SET_SECTIONS':
      return {
        ...state,
        sectionsForm: action.payload
      };
    case 'SET_TABLES':
      return {
        ...state,
        tablesForm: action.payload
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
}
