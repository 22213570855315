import axiosInstance from 'utils/axios';

export const Login = (email, password) => {
    return axiosInstance.post('auth/sign-in', {
        email,
        password
    });
};

export const Authme = () => {
    return axiosInstance.get('auth/me');
};
