// assets
import {
  IconBrandChrome,
  IconHelp,
  IconDashboard,
  IconBuildingCommunity,
  IconUsers,
  IconInbox
} from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp, IconDashboard, IconBuildingCommunity, IconUsers, IconInbox };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'companies',
      title: 'Companies',
      type: 'item',
      url: '/companies',
      icon: icons.IconBuildingCommunity,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'contacts',
      title: 'Contacts',
      type: 'item',
      url: '/contacts',
      icon: icons.IconInbox,
      breadcrumbs: false
    }
    // {
    //     id: 'sample-page',
    //     title: 'Sample Page',
    //     type: 'item',
    //     url: '/sample-page',
    //     icon: icons.IconBrandChrome,
    //     breadcrumbs: false
    // }
  ]
};

export default other;
