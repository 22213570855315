import { lazy } from 'react';

// project imports
// import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// lulpos Pages
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const CompaniesPage = Loadable(lazy(() => import('views/companies')));
const UsersPage = Loadable(lazy(() => import('views/users/index')));
const ContactsPage = Loadable(lazy(() => import('views/contacts')));
const Createuser = Loadable(lazy(() => import('views/users/Createuser')));
const Viewuser = Loadable(lazy(() => import('views/users/Viewuser')));
const EditUser = Loadable(lazy(() => import('views/users/EditUser')));
const Viewcompany = Loadable(lazy(() => import('views/companies/Viewcompany')));
const Editcompany = Loadable(lazy(() => import('views/companies/Edit/index')));
const Manual = Loadable(lazy(() => import('views/companies/Manual/Index')));
const Automatic = Loadable(lazy(() => import('views/companies/Automatic/Index')));

// ==============================|| MAIN ROUTING ||============================== //
import Protected from './Protected';

const MainRoutes = {
  path: '/',
  element: <Protected />,
  children: [
    {
      path: '/',
      element: <DashboardPage />
    },
    // {
    //     path: 'dashboard',
    //     children: [
    //         {
    //             path: 'default',
    //             element: <DashboardDefault />
    //         }
    //     ]
    // },

    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'companies',
      element: <CompaniesPage />
    },
    {
      path: 'company/:id',
      element: <Viewcompany />
    },
    {
      path: 'company/:id/edit',
      element: <Editcompany />
    },
    {
      path: 'company/create/manual',
      element: <Manual />
    },
    {
      path: 'company/create/automatic',
      element: <Automatic />
    },
    {
      path: 'users',
      element: <UsersPage />
    },
    {
      path: 'createuser',
      element: <Createuser />
    },
    {
      path: 'user/:id',
      element: <Viewuser />
    },
    {
      path: 'user/edit/:id',
      element: <EditUser />
    },
    {
      path: 'contacts',
      element: <ContactsPage />
    }
  ]
};

export default MainRoutes;
