import { combineReducers } from 'redux';

// reducer import
import authReducer from './authReducer';
import automatic from './Company/Automatic/reducers';
import customizationReducer from './customizationReducer';
import edit from 'store/Company/Edit/reducers';
import manual from './Company/Manual/reducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    authReducer: authReducer,
    automaticReducer: automatic,
    customization: customizationReducer,
    editReducer: edit,
    manualReducer: manual
});

export default reducer;
