export const initialState = {
  activeShop: '',
  shop: {
    shopName: ''
  },
  shops: [],
  deletedShops: [],
  deletedShopDevices: [],
  deletedPaymentMethods: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ACTIVE_SHOP':
      return {
        ...state,
        activeShop: action.payload
      };
    case 'SET_SHOP':
      return {
        ...state,
        shop: action.payload
      };
    case 'SET_SHOPS':
      return {
        ...state,
        shops: action.payload
      };
    case 'SET_DELETED_SHOPS':
      return {
        ...state,
        deletedShops: action.payload
      };
    case 'RESET_SHOP':
      return {
        ...state,
        shop: {
          shopName: ''
        }
      };
    case 'SET_SHOP_DEVICE':
      const shopDevice = action.payload.shopDevice;
      const shop = action.payload.shop;
      const updatedShop = {
        ...shop,
        shopDevices: shop.shopDevices ? [...shop.shopDevices, shopDevice] : [shopDevice]
      };

      const filteredShops = state.shops.filter((currentShop) => currentShop.id !== shopDevice.shopId);

      return {
        ...state,
        shops: [...filteredShops, updatedShop]
      };

    case 'SET_PAYMENT_METHOD':
      const paymentMethod = action.payload.paymentMethod;
      const shopPaymentMethod = action.payload.shop;
      const updatedShoppaymentmethod = {
        ...shopPaymentMethod,
        paymentMethods: shopPaymentMethod.paymentMethods
          ? [...shopPaymentMethod.paymentMethods, paymentMethod]
          : [paymentMethod]
      };
      const selectOtherShops = state.shops.filter((currentShop) => currentShop.id !== paymentMethod.shopId);
      return {
        ...state,
        shops: [...selectOtherShops, updatedShoppaymentmethod]
      };

    case 'DELETE_SHOP_DEVICE':
      const selectedshop = state.shops.filter((currentShop) => currentShop.id === action.payload.shop.id);
      const filteredshops = state.shops.filter((currentShop) => currentShop.id !== selectedshop[0].id);
      const shopdevice = selectedshop[0].shopDevices.filter((device) => device.id === action.payload.device);
      const updatedShopDevices = selectedshop[0].shopDevices.filter(
        (device) => device.id !== action.payload.device
      );
      const modiefiedShop = {
        ...selectedshop[0],
        shopDevices: updatedShopDevices
      };
      let deletedshopDevices = state.deletedShopDevices;
      if (shopdevice[0].action !== 'new') {
        deletedshopDevices = [...state.deletedShopDevices, shopdevice[0]];
      }
      return {
        ...state,
        shops: [...filteredshops, modiefiedShop],
        deletedShopDevices: [...deletedshopDevices]
      };
    case 'DELETE_PAYMENT_METHOD':
      const deletingPaymentMethod = action.payload.paymentMethod;
      const deletingShopPaymentMethod = action.payload.shop;
      const otherPaymentMethods = deletingShopPaymentMethod.paymentMethods.filter(
        (paymentMethod) => paymentMethod.id !== deletingPaymentMethod.id
      );
      const otherfilteredshops = state.shops.filter(
        (currentShop) => currentShop.id !== deletingShopPaymentMethod.id
      );
      const modiefiedShopPaymentMethods = {
        ...deletingShopPaymentMethod,
        paymentMethods: [...otherPaymentMethods]
      };
      let deletedPaymentMethods = state.deletedPaymentMethods;
      if (deletingPaymentMethod.status !== 'new') {
        deletedPaymentMethods = [...state.deletedPaymentMethods, deletingPaymentMethod];
      }
      return {
        ...state,
        shops: [...otherfilteredshops, modiefiedShopPaymentMethods],
        deletedPaymentMethods: [...deletedPaymentMethods]
      };
    case 'EDIT_SHOP_DEVICE':
      const shopDeviceData = action.payload.data;
      const deviceShop = state.shops.filter((currentShop) => currentShop.id === action.payload.shopId);
      const selectedDevice = deviceShop[0].shopDevices.filter(
        (device) => device.id === action.payload.device
      );
      selectedDevice[0].action = selectedDevice[0].action === 'new' ? 'new' : 'updated';

      const updatedShopDevice = {
        ...selectedDevice[0],
        deviceName: shopDeviceData.deviceName,
        deviceModel: shopDeviceData.deviceModel,
        status: shopDeviceData.status,
        imei: shopDeviceData.imei
      };
      const filteredShopDevices = deviceShop[0].shopDevices.filter(
        (device) => device.id !== action.payload.device
      );
      const oldShops = state.shops.filter((currentShop) => currentShop.id !== action.payload.shopId);
      const newshop = {
        ...deviceShop[0],
        shopDevices: [...filteredShopDevices, updatedShopDevice]
      };
      return {
        ...state,
        shops: [...oldShops, newshop]
      };
    case 'EDIT_PAYMENT_METHOD':
      const editedPaymentMethod = action.payload.paymentMethod;
      const editedShopPaymentMethod = state.shops.filter(
        (currentShop) => currentShop.id === editedPaymentMethod.shopId
      );
      const remainingPaymentMethod = editedShopPaymentMethod[0].paymentMethods.filter(
        (paymentMethod) => paymentMethod.id !== editedPaymentMethod.id
      );
      const remainingShops = state.shops.filter(
        (currentShop) => currentShop.id !== editedPaymentMethod.shopId
      );
      editedPaymentMethod.status = editedPaymentMethod.status === 'new' ? 'new' : 'updated';
      editedShopPaymentMethod[0].paymentMethods = [...remainingPaymentMethod, editedPaymentMethod];
      return {
        ...state,
        shops: [...remainingShops, editedShopPaymentMethod[0]]
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
}
