export const initialState = {
    activeStep: 0,
    companyName: '',
    user: {
        fullName: '',
        phoneNumber: '',
        email: '',
        password: ''
    },
    shops: []
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_STEP':
            return {
                ...state,
                activeStep: action.payload
            };
        case 'SET_COMPANY_NAME':
            return {
                ...state,
                companyName: action.payload
            };
        case 'SET_USER_MANUAL':
            const { name, value } = action.payload;
            const user = {
                ...state.user,
                [name]: value
            };
            return {
                ...state,
                user
            };
        case 'SET_SHOPS':
            const shops = state.shops;
            shops.push(action.payload);
            return {
                ...state,
                shops
            };
        case 'REMOVE_SHOP':
            const newShops = state.shops.filter((shop) => shop !== action.payload);
            return {
                ...state,
                shops: newShops
            };
        case 'RESET_manualOnboarding':
            const data = {
                ...initialState,
                shops: []
            };
            return data;
        default:
            return state;
    }
}
